import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import {Container} from "react-bootstrap";
import SimpleBlock from "../../components/misc/simple-block";
import HeroPages from "../../components/heroes/hero-pages";
import PressReleasesData from "../../content/press-releases/index.json";

const PressReleasesIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo
        lang={intl.locale}
        title={PressReleasesData[intl.locale].title}
        description={PressReleasesData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroPages
      theme={`primary`}
      content={PressReleasesData[intl.locale]}
    />
    <Container>
      {PressReleasesData[intl.locale].pressReleases.map((item, i) => {

          return (
              <SimpleBlock key={i} content={item} />
          )

      })}
    </Container>
  </Layout>
)

export default injectIntl(PressReleasesIndexPage)
